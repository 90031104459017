import React, { Component, useEffect, useState } from 'react'
import "../styles/booking.css"
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";
import { collection, getDocs, getDoc, doc, updateDoc, increment} from "firebase/firestore";
import {db} from "../firebase"
import { async } from '@firebase/util';

export default function Booking() {

    const [formValues, setFormValues] = useState(
        [{ request_type: "", req_airport: "", slot_type : "", flight_number: "", date: "", seats:"", aircraft_type:"", time:"", stc:"", reg:""}])
    
    const [airportValues, setAirportValues] = useState([])

    let iata_season = "W24";

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value.toUpperCase();
        setFormValues(newFormValues);
    }

    const getEmails = async (iata) =>{
        let recentsList;

        const docRef = doc(db, "airports", iata);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            recentsList = docSnap.data().emails.join("; ")
        } else {
            recentsList = ""
        }
        return recentsList
    }

    const getGAEmails = async (iata) => {
        let recentsList;

        const docRef = doc(db, "airports", iata);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            recentsList = docSnap.data().ga.join("; ")
        } else {
            recentsList = ""
        }
        return recentsList
    }

    let addSlot = () => {
        setFormValues([...formValues, {request_type: "N", req_airport: "", slot_type: "", flight_number: "", date:"", seats:"", aircraft_type:"", time:"", stc:"", reg:"", da:""}])
    }

    let deleteSlot = () => {
        setFormValues([...formValues, {request_type: "D", req_airport: "", slot_type: "", flight_number: "", date:"", seats:"", aircraft_type:"", time:"", stc:"", reg:"", da:""}])
    }
    let changeSlot = () => {
        setFormValues([...formValues, {request_type: "C", req_airport: "", slot_type: "", flight_number: "", date:"", seats:"", aircraft_type:"", time:"", stc:"", reg:"", da:""}, 
        {request_type: "R", req_airport: "", slot_type: "", flight_number: "", date:"", seats:"", aircraft_type:"", time:"", stc:"", reg:"", da:""}])
    }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let removeFormFieldsC = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 2);
        setFormValues(newFormValues)
    }

    let todaysDate = () =>{
        let monthsList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
        const date = new Date();
        let month_no = date.getUTCMonth();
        let month = monthsList[month_no]
        let day = date.getUTCDate().toString();
        if(day.length === 1){
            day = "0" + day
        }
        let result = day + month
        return result
    }

    let date = todaysDate()
    
    let handleSubmit = (event) => {
        const d = new Date();
        let str= "New SCRs created at " + d.getHours().toString() + ":" + d.getMinutes().toString() + ":" + d.getSeconds().toString()
        window.alert(str);
        event.preventDefault();
        //console.log(JSON.stringify(formValues));
        let result = [];
        for(let i = 0; i < formValues.length; i++){
        if(result.indexOf(formValues[i].req_airport) === -1 && formValues[i].req_airport != ""){
                result.push(formValues[i].req_airport);
        }
        }
        setAirportValues(result)
    }

    let generateLine = (value) =>{
        let monthsList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
        let month_no = parseInt(value.date[5] + value.date[6])
        let month = monthsList[month_no - 1]
        let day = value.date[8] + value.date[9]
        let week_date_raw = new Date(value.date).getDay()

        let week_date = ""

        if (week_date_raw === 0) {
            week_date = "0000007"
        } else if (week_date_raw === 1) {
            week_date = "1000000"
        } else if (week_date_raw === 2){
            week_date = "0200000"
        } else if (week_date_raw === 3){
            week_date = "0030000"
        } else if (week_date_raw === 4){
            week_date = "0004000"
        } else if (week_date_raw === 5) {
            week_date = "0000500"
        } else if (week_date_raw === 6) {
            week_date = "0000060"
        }
        
        let result = ""
        //console.log(week_date)
        
        result = result + value.request_type
        if (value.slot_type === "DEPARTURE") {
            result = result + " "
        }
        result = result + value.flight_number + " "
        result = result + day + month + day + month + " " + week_date + " " + value.seats + value.aircraft_type + " "
        if (value.slot_type === "DEPARTURE") {
            result = result + value.time + value.da
        } else {
            result = result + value.da + value.time
        }
        result = result + " " + value.stc
        result = result + "%0A"

        return result
    }


    let scrGenerate = async (name) => {

        let requests = ""
        const requestTypes = []
        let subject = ""
        let gaState = true
        
        for (let i = 0; i < formValues.length; i++) {
            //console.log("b")
            if(formValues[i].req_airport === name){
                requests = requests + generateLine(formValues[i])
                requestTypes.push(formValues[i].request_type)
            }
        }

        for (let i = 0; i < formValues.length; i++) {
            if(formValues[i].stc !== "D"){
                gaState = false;
                break;
            }
        }

        const allEqual = (arr) =>{
            let result = true
            if(arr.length > 1){
                if(arr.indexOf("C") != -1) {
                    if(arr.indexOf("D") != -1 || arr.indexOf("N") != -1){
                        return false
                    } else {
                        return true
                    }
                } if (arr.indexOf("N") != -1){
                    if (arr.indexOf("D") != -1){
                        return false
                    } else {
                        return true
                    }
                }  if (arr.indexOf("D") != -1){
                    if (arr.indexOf("N") != -1){
                        return false
                    } else {
                        return true
                    }
            }}
            return true
        }
        
        //console.log(allEqual(requestTypes))

        if (allEqual(requestTypes) === true) {
            //console.log(requestTypes[0])
            if(requestTypes[0] === "N") {
                subject = `NEW SLOT REQ ${name}`
            } else if (requestTypes[0] === "D"){
                subject = `SLOT CANX REQ ${name}`
            } else if (requestTypes[0] === "C") {
                subject = `SLOT CHG REQ ${name}`
            }
        } else if (allEqual(requestTypes) === false){
            subject = `SLOT REQ ${name}`
        }
        

        var final_text = [
            `SCR%0A${iata_season}%0A${todaysDate()}%0A${name}%0A${requests}%0ASI ${subject}`
        ]

        let emails = await getEmails(name);
        

        if (gaState == true){
            let gaEmails = await getGAEmails(name);
            emails = emails + "; " + gaEmails;
        }

        window.open(`mailto:${emails}?cc=slotdesk@ryanair.com&subject=${subject}&body=${final_text}`);

    }


    const navigate = useNavigate();

    let handleReload = () =>{
        navigate(0);
    }
    

    //To start with no form values
    if(formValues.length === 1 && formValues[0]["request_type"] == ""){
        formValues.pop()
    }

    return (
        <div className='booking_back_container'>
            <div className='booking_top_container'>
                <div className='top_top_container'>
                    <div className='booking_text_container'>
                        <div className='booking_req_container'>
                            <h2 className='req_slots_title'>Step 1 - Request Slots</h2>
                        </div>
                        <div className='booking_btn_container'>
                            <button className="button_add" type="button" onClick={() => addSlot()}>Request New Slot</button>
                            <button className="button_add" type="button" onClick={() => changeSlot()}>Change Slot</button>
                            <button className="button_add" type="button" onClick={() => deleteSlot()}>Cancel Slot</button>
                        </div>
                    </div>
                        <form  id="forma" onSubmit={handleSubmit}> 
                        {formValues.map((element, index ) => (
                        <div className="form-inline" key={index}>
                        <div className='form-small'>
                            <label><br/>{element.request_type}</label>
                        </div>
                        <div className='form-large'>
                            <label>Slot Type</label>
                            <select name="slot_type" onChange={e => handleChange(index, e)} required>
                                <option value="">Select One</option>
                                <option value="arrival">Arrival</option>
                                <option value="departure">Departure</option>
                            </select>
                        </div>
                        <div className='form-small' id="airport">
                            <label>Airport</label>
                            <input type="text" name="req_airport" value={element.req_airport || ""} onChange={e => handleChange(index, e)} maxlength="3" minLength="3" required/>
                        </div>
                        <div className='form-med'>
                            <label>Flight No</label>
                            <input type="text" name="flight_number" value={element.flight_number || ""} onChange={e => handleChange(index, e)} minLength="3" required/>
                        </div>
                        <div className='form-each'>
                            <label>Date</label>
                            <input type="date" name="date" value={element.date|| ""} onChange={e => handleChange(index, e)} required />
                        </div>
                        <div className='form-small'>
                            <label>Seats</label>
                            <input type="text" name="seats" value={element.seats || ""} onChange={e => handleChange(index, e)} maxlength="3" minLength="3" required/>
                        </div>
                        <div className='form-med'>
                            <label>A/C Type</label>
                            <input type="text" name="aircraft_type" value={element.aircraft_type || ""} onChange={e => handleChange(index, e)} maxlength="3" minLength="3" required/>
                        </div>
                        <div className='form-small'>
                            <label>Time</label>
                            <input type="text" name="time" value={element.time || ""} onChange={e => handleChange(index, e)} maxlength="4" minLength="4" required/>
                        </div>
                        <div className='form-small'>
                            <label>D/O</label>
                            <input type="text" name="da" value={element.da || ""} onChange={e => handleChange(index, e)} maxlength="3" minLength="3" required/>
                        </div>
                        <div className='form-large'>
                            <label>STC</label>
                            <select name="stc" onChange={e => handleChange(index, e)} required>
                                <option value="">Select One</option>
                                <option value="A">A - Additional Cargo/Mail</option>
                                <option value="B">B - Additional Passenger Shuttle</option>
                                <option value="C">C - Passenger Charter</option>
                                <option value="D">D - General aviation </option>
                                <option value="E">E - Special - FAA govt</option>
                                <option value="F">F - Cargo Scheduled</option>
                                <option value="G">G - Additional Passenger Scheduled</option>
                                <option value="H">H - Cargo Charter</option>
                                <option value="J">J - Passenger Scheduled</option>
                                <option value="K">K - Training</option>
                                <option value="L">L - Passenger/Cargo Combi Charter</option>
                                <option value="M">M - Mail Scheduled</option>
                                <option value="N">N - Business aviation - Air Taxi </option>
                                <option value="O">O - Charter Flight transit</option>
                                <option value="P">P - Positioning - Non-Revenue</option>
                                <option value="Q">Q - Passenger/Cargo Combi Scheduled</option>
                                <option value="R">R - Additional Passenger/Cargo Combi Charter</option>
                                <option value="S">S - Passenger Shuttle</option>
                                <option value="T">T - Technical Test</option>
                                <option value="U">U - Air ambulance / Humanitarian</option>
                                <option value="W">W - Military</option>
                                <option value="X">X - Technical Stop</option>
                            </select>
                        </div>
                        <label></label>
                        {
                            formValues[index]["request_type"] === "N" || formValues[index]["request_type"] == "D"?
                            <button type="button"  className="btn_remove" onClick={() => removeFormFields(index)}> x </button>
                            :null
                        }
                        {
                            formValues[index]["request_type"] === "C"?
                            <button type="button"  className="btn_remove" onClick={() => {removeFormFieldsC(index)}}> x </button>
                            :null
                        }
                        </div>
                    ))}
                    <div className="button-section">
                        {
                            formValues.length?
                            <button className="button_active" type="submit">Genererate SCR's</button>
                            : null
                        }
                                                {
                            formValues.length?
                            <button className="button_warning" type="button"  onClick={() => {handleReload()}}>Reset</button>
                            : null
                        }
                    </div>
                </form>
                </div>
                <div className='bot_bot_container'>
                    <div>
                        <div className="booking_text_container">
                            <div className='scr_title'>
                                <h2>Step 2 - Send SCR's</h2>
                            </div>
                        </div>
                        <div>
                            {
                                airportValues.map((element, index) => (
                                    <div className='scr_line'>
                                        <div className='scr_line_one'>
                                            <p>{airportValues[index]}</p>
                                        </div>
                                        <div className='scr_line_two'>
                                            <button className="button_add" onClick={async () => await scrGenerate(airportValues[index])}>Email</button>
                                        </div>
                                        
                                    </div>
                                ))
                            }
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
